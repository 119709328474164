/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');


$('body').scrollspy({
  offset: 50
});

function borderChange(element, color) {
  element.style.borderColor = color;
}

$('.list-group-item').on('click', (e) => {
  e.preventDefault();
  let url = e.target.href;
  console.log(url);
  let hash = url.substring(url.indexOf('#'));

  $('html, body').animate({
    scrollTop: $(hash).offset().top - 40
  }, 500);
});

$(document).ready(calcMargin);

$(window).resize(calcMargin);

$(':not(.se-image-container) + .se-image-container, * > .se-image-container:first-of-type').
each(function() {
  $(this).
  nextUntil(':not(.se-image-container)').
  addBack().
  wrapAll('<section class="image-container" />');
});

$('.image-container').each(function() {
  let images = $(this).find('img');
  const imgCount = images.length;
  let counter = 0;
  images.unwrap().unwrap();

  images.each(function() {
    let src = $(this).attr('src');
    $(this).removeAttr("style");
    let newFilename = src.replace('thumbs/', '');

    $(this).attr('src', src.replace(src, newFilename));

    if (imgCount >= 5) {
      let activeClass = counter === 0 ? 'active' : '';
      $(this)
        .wrap("<div class='carousel-item " + activeClass + "'></div>")
        .wrap("<a data-fancybox=\"gallery\" href='" + newFilename + "'></a>");
    } else {
      $(this).wrap("<a data-fancybox=\"gallery\" href='" + newFilename + "'></a>");
    }

    counter++;
  });

  if (imgCount > 0 && imgCount < 5) {

    if (imgCount % 2 === 0) {
      $(this).addClass('two-cols');
    }

    if (imgCount % 3 === 0) {
      $(this).addClass('three-cols');
    }
  } else if (imgCount >= 5) {
    let newElement = $("<div class='carousel slide' data-ride='carousel' id='carousel'>");
    let newSubElement = $('<div class="carousel-inner">');

    newElement.html(newSubElement.html($(this).html()));

    $(this).replaceWith(newElement);
  }
})

$('#article p').each(function() {
  let images = $(this).find('img');
  let imgCount = images.length;
  let counter = 0;
  images.each(function() {
    let src = $(this).attr('src');
    let newFilename = src.replace('thumbs/', '');

    $(this).attr('src', src.replace(src, newFilename));

    if (imgCount >= 5) {
      let activeClass = counter === 0 ? 'active' : '';
      $(this)
        .wrap("<div class='carousel-item "+ activeClass + "'></div>")
        .wrap("<a data-fancybox=\"gallery\" href='"+newFilename+"'></a>");
    } else {
      $(this).wrap("<a data-fancybox=\"gallery\" href='"+newFilename+"'></a>");
    }

    counter++;
  });

  let carouselId = 'carousel' + imgCount;

  counter = 0;

  if (imgCount > 0 && imgCount < 5) {
    let newElement = $("<section>");
    newElement.addClass('image-container');

    if (imgCount % 2 === 0) {
      newElement.addClass('two-cols');
    }

    if (imgCount % 3 === 0) {
      newElement.addClass('three-cols');
    }

    newElement.html($(this).html());

    $(this).replaceWith(newElement);
  } else if (imgCount >= 5) {
    let newElement = $("<div class='carousel slide' data-ride='carousel' id='carousel'>");
    let newSubElement = $('<div class="carousel-inner">');

    newElement.html(newSubElement.html($(this).html()));

    $(this).replaceWith(newElement);
  }
});

$('.carousel').append('<a class="carousel-control-prev" href="#carousel" role="button" data-slide="prev"><span class="carousel-control-prev-icon" aria-hidden="true"></span> </a> <a class="carousel-control-next" href="#carousel" role="button" data-slide="next"> <span class="carousel-control-next-icon" aria-hidden="true"></span></a>')
  .carousel({
    interval: 6000,
  });

function calcMargin() {
  var width = $('#article').width() - $('blockquote').width();
  var leftMargin = width/2;

  $('blockquote').css('margin-left', leftMargin);
}

$('#contact-me').submit(function(e){
  form = this;
  e.preventDefault();
  $('#formSave').prop("disabled", true);

  if (form.checkValidity() === false) {
    e.stopPropagation();
  }
  form.classList.add('was-validated');

  $.ajax({
    type: 'POST',
    url: '/contact',
    data: $(this).serialize(),
    dataType: 'json',
    success: function(res) {
      $('#formSave').removeAttr("disabled");
      $('#contact-me')[0].reset();
      $('#contact-me').removeClass('was-validated');

      $('#formAlert').fadeIn(500);
      setTimeout(function() {
        $('#formAlert').fadeOut(500);
      }, 3000);

    },
    error: function(err) {
      $('#formSave').removeAttr("disabled");

      let errors = err.responseJSON;
      console.log(errors);

      let errorsHtml = '<div class="alert alert-danger"><ul>';

      $.each( errors.error, function( key, value ) {
        errorsHtml += '<li>'+ value[0] + '</li>';
      });
      errorsHtml += '</ul></div>';

      $( '#form-errors' ).html( errorsHtml );
    }
  });
});

if ($('#header').length > 0) { // check if element exists
  var last_scroll_top = 0;
  $(window).on('scroll', function() {
    scroll_top = $(this).scrollTop();

    if(scroll_top < last_scroll_top || scroll_top < 10) {
      $('#header').removeClass('scrolled-down').addClass('scrolled-up');
    }
    else {
      $('#header').removeClass('scrolled-up').addClass('scrolled-down');
    }
    last_scroll_top = scroll_top < 0 ? 0 : scroll_top;
  });
}

$(".see-more").on('click', function() {
  let $div = $($(this).data('div')); //div to append
  let link = $(this).data('link'); //current URL

  let page = $(this).data('page'); //get the next page #
  let lastPage = $(this).data('last');
  let href = link + page; //complete URL
  $.get(href, function(response) { //append data
    $div.append(response);
  });

  $(this).data('page', (parseInt(page) + 1)); //update page #

  let newPage = $(this).data('page');
  if (newPage > lastPage) {
    $(this).hide();
  }
});
